.service-type-image {
    object-fit: cover;
    border-radius: 10px;
}

.subtitle{
    color: #91877F;
    font-size: 1.3rem;
    line-height: 1.75rem;
    letter-spacing: -0.015em;
    text-transform: capitalize;
}

@media screen and (max-width: 640px) {
    .mobile-service-types {
        flex-direction: column;
        gap: 35px;
    }
}
