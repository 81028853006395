.book-btn {
  background: darkorange;
  color: white;
}

.phone-link {
  font-size: 0.87em;
}

.subnav-dropdown-3-columns {
  min-width: 600px;
}

.sub-nav-item {
  border-bottom: 1px solid #e5e5e5;
  padding: 0.5rem 0.37rem;
}

.sub-nav-item-shorter {
  padding: 0.37rem !important;
}

.sub-nav-item a {
  color: #202945;
  font-size: 0.93em;
}

.sub-nav-link {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  white-space: nowrap;
}

.sub-nav-link-disabled {
  pointer-events: none;
  color: #adadad !important;
}