.prices-component {
    background-color: #008487;
    text-align: center;
}

.prices-button {
    background-color: white !important;
    border-color: white !important;
}

@media screen and (min-width: 768px) {
    .container {
        max-width: 1024px !important;
    }
    .image-wrapper {
        width: 15em;
    }
    .content-wrapper {
        max-width: 320px;
    }
}
