.container {
    border: none !important;
}

.title {
    text-shadow: 2px 2px #000000;
}

.custom_page {
    margin-left: auto;
    margin-right: 10%;
}
