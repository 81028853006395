.star > svg {
    height: 19px;
    color: #f34113 !important;
}

.review-box-ftdr {
    background: #FBF7F4;
    border-radius: 10px;
    box-shadow: 0px 10px 20px -5px rgba(40, 40, 40, 0.09);
    padding: 1.88rem 1.56rem 1.9rem;
    min-height: 315px;
}

@media (min-width: 1024px) {
    .review-box-ftdr {
        padding: 1.88rem 1.9rem 1.9rem;
    }
}

.review-box-ftdr .star > svg {
    height: 23.94px;
}

.review-text-ftdr {
    line-height: 27.9px;
}

@media (min-width: 1024px) {
    .review-text-ftdr {
        font-size: 1.12rem;
    }
}
