.separator {
    top: 142px;
    left: 15%;
    height: 1px;
    right: 15%;
    background-color: #008487;
}

.separator-ftdr {
    top: 137px;
    left: 17%;
    right: 17%;
}

.hiw-section {
    max-width: 920px;
}

.hiw-section-ftdr {
    max-width: 1150px;
}

.text {
    color: #F34113;
}

.border-fd-teal {
    border-color: #008487;
    color: #008487;
}

.hiw-steps-numbers-ftdr {
    color: white;
    background-color: #008487;
}

.hiw-description-ftdr {
    line-height: 27.9px;
    font-size: 1.125rem;
    letter-spacing: 0px;
}

.hiw-section-ftdr h4 {
    font-size: 1.25rem !important;
    line-height: 28px;
    letter-spacing: 0px;
}

.disclaimer-ftdr {
    font-size: 0.875rem;
    letter-spacing: 0px;
    max-width: 760px;
}

.disclaimer-ftdr a {
    color: #028386;
    text-decoration: underline;
}