.container {
    max-width: 80% !important;
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 1024px !important;
    }
    .image-wrapper {
        width: 15em;
    }
    .content-wrapper {
        max-width: 320px;
    }
}
